import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import LooseObject from '@/models/Objects/LooseObject';
import VueBase64FileUpload from 'vue-base64-file-upload';

@Component({
    components: { VueBase64FileUpload },
})
export default class DocumentDisplay extends Vue {
    @Prop() public props!: LooseObject;
    @Prop() public value!: string;
    private file: LooseObject = {
        lastModifiedDate: String,
        name: String,
        size: Number,
        type: String,
        data: String,
    };
    private vModelName: boolean = false;
    protected created() {
        let index = 0;
        this.props.filenames = [];
        this.props.filedata = [];
        this.props.filetypes = [];
        for (const items of Object.keys(this.props.files)) {
            this.props.filenames[index] = this.props.files[items].name.match(/^.*(?=(\.))/)[0].replace(' ', '');
            this.props.filedata[index] = this.props.files[items].data;
            this.props.filetypes[index] = this.props.files[items].type;
            this.props.filedata[index] = 'data:' + this.props.filetypes[index] + ';base64,'
            + this.props.filedata[index];
            const varName = this.props.filenames[index];
            this.props[varName] = false;
            index = index + 1;
        }
    }



    private onFile(file: LooseObject) {
        this.file.lastModifiedDate = file.lastModifiedDate;
        this.file.name = file.name;
        this.file.size = file.size;
        this.file.type = file.type;
    }

    private onLoad(data: string) {
        this.file.data = data;
        this.$emit('update:value', data);
    }


    private showModal(modalName: string) {
        this.props[modalName] = true;
        this.vModelName = true;
    }

    private handleOk(modalName: string) {
        this.props[modalName] = false;
        this.vModelName = false;
    }

    private getName(modalName: string) {
        return modalName;
    }
}
