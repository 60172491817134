import { Prop, Component, Vue, Ref } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import LooseObject from '@/models/Objects/LooseObject';
import MenuModel from '@/models/Objects/MenuModel';
import MyMenuItem from '@/models/Objects/MenuItem';
import store from '@/store';
import Home from '@/views/Home/Home';

@Component
export default class SideMenu extends Vue {
    @Ref('Menu') private menu?: HTMLElement;
    private collapsed: boolean = false;
    private home = new Home();
    @Prop() private sideMenu?: MenuModel;
    private openKeys: string[] = [];

    protected toggleCollapsed() {
        this.collapsed = !this.collapsed;
    }


    protected mounted() {

        EventBus.$on('update-menu-side', (responseMenu: any) => {
            this.sideMenu = responseMenu;
            if (this.openKeys.length === 0) {
                if (this.sideMenu) {
                    for (let i = 0; i < this.sideMenu.menuItems.length; i++) {
                        if (this.sideMenu?.menuItems[i].title) {
                            this.openKeys.push(this.sideMenu?.menuItems[i].title);
                        }
                    }
                }
            }
        });
    }

    private resolveWorkflow(workflow: string) {
        store.commit('updateWorkflow', workflow);
        EventBus.$emit('resolveWorkflow');
    }

    private updateKeys(key: string) {
        if (this.openKeys.includes(key)) {
            delete (this.openKeys[this.openKeys.indexOf(key)]);
        } else {
            this.openKeys.push(key);
        }
    }
}
