import { Prop, Component, Vue } from 'vue-property-decorator';
import LooseObject from '@/models/Objects/LooseObject';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import { EventBus } from '@/utilities/eventBus/EventBus';
import { getFormattedString } from '@/utilities/mutate';


@Component
export default class SliderInput extends Vue {
    @Prop() public value!: number;
    @Prop() public props!: LooseObject;
    private validationArray: ValidationModel[] = [];



    private inputValue = this.props.defaultEditValue || this.props.minValue;
    private prefix: string = '';

    protected created() {
        EventBus.$on('requestValue', this.submitValue);
        EventBus.$on('setInitialValues', this.setInitialObjectValues);
        this.createValidationObject();
        this.setInitialObjectValues();
        this.updatePrefix();
    }

    protected beforeDestroy() {
        EventBus.$off('requestValue', this.submitValue);
    }

    private setInitialObjectValues() {
        this.$emit('update:value', this.inputValue);
        this.updatePrefix();
    }

    private isValueValid() {
        if (this.props.required && !this.value) {
          return false;
        }
        return true;
    }

    private submitValue() {
        const formattedString = getFormattedString(this.props, this.value);
        if (this.isValueValid()) {
            EventBus.$emit('submitValue', this.value, this.props.applicationField, this.props.label, formattedString);
        }
    }

    private createValidationObject() {
        this.validationArray = [
            {
                required: this.props.required,
                message: this.props.requiredMessage || 'Please fill out this field',
                trigger: 'change',
            },
        ];
    }

    private updatePrefix() {
        if (this.props.unit !== undefined && this.inputValue > 1) {
            this.prefix = `${this.props.unit}s`;
        } else {
            this.prefix = this.props.unit || getFormattedString(this.props, this.inputValue).substring(0, 1);
        }
    }

    private updateInputValue(value: string | number) {
        if (value === '') {
            this.$emit('update:value', this.props.minValue);
            this.inputValue = this.props.minValue;
        } else {
            this.$emit('update:value', parseInt(String(value), 10));
        }
        this.updatePrefix();
    }
}
