import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import LooseObject from '@/models/Objects/LooseObject';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';

@Component
export default class EmailInput extends Vue {
    @Prop() public props!: LooseObject;
    @Prop() public value!: string;
    private validationArray: ValidationModel[] = [];

    protected created() {
        EventBus.$on('requestValue', this.submitValue);
        this.createValidationObject();
        if (this.props.defaultEditValue !== null) {
            this.$emit('update:value', this.props.defaultEditValue);
        }
    }

    protected beforeDestroy() {
        EventBus.$off('requestValue', this.submitValue);
    }

    private isValueValid() {
        if (this.props.required && !this.value) {
          return false;
        }
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.value);
    }

    private submitForm() {
        document.getElementById('submit')?.click();
    }

    private submitValue() {
        if (this.isValueValid()) {
            EventBus.$emit('submitValue', this.value, this.props.applicationField, this.props.label );
        }
    }

    private createValidationObject() {
        this.validationArray = [
            {
                required: this.props.required,
                message: this.props.requiredMessage || 'Please fill out this field',
                trigger: 'blur',
            },
            { pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, message: this.props.validationMessage || 'Please enter a valid email address', trigger: 'blur' },
        ];
    }
}
