import ComponentListModel from '@/models/Components/ComponentListModel';
import Title from './Title/Title.vue';
import TextContent from './TextContent/TextContent.vue';
import PhoneInput from './Inputs/PhoneInput/PhoneInput.vue';
import DateInput from './Inputs/DateInput/DateInput.vue';
import EmailInput from './Inputs/EmailInput/EmailInput.vue';
import SubmitButton from './Buttons/SubmitButton/SubmitButton.vue';
import SliderInput from './Inputs/SliderInput/SliderInput.vue';
import DecisionImage from './DecisionImage/DecisionImage.vue';
import TextInput from './Inputs/TextInput/TextInput.vue';
import SelectInput from './Inputs/SelectInput/SelectInput.vue';
import NumberInput from './Inputs/NumberInput/NumberInput.vue';
import HiddenInput from './Inputs/HiddenInput/HiddenInput.vue';
import RadioInput from './Inputs/RadioInput/RadioInput.vue';
import RadioButtonInput from './Inputs/RadioButtonInput/RadioButtonInput.vue';
import FileInput from './Inputs/FileInput/FileInput.vue';
import TableInput from './Inputs/TableInput/TableInput.vue';
import DocumentDisplay from './DocumentDisplay/DocumentDisplay.vue';
import CheckboxInput from './Inputs/CheckboxInput/CheckboxInput.vue';
import AddressInput from './Inputs/AddressInput/AddressInput.vue';
import SingleSignOnInput from './Inputs/SingleSignOnInput/SingleSignOnInput.vue';

import Widget from './Widget/Widget.vue';

export const componentList: ComponentListModel[] = [
    { ref: 'title', component: Title },
    { ref: 'text', component: TextContent },
    { ref: 'input_text', component: TextInput },
    { ref: 'input_select', component: SelectInput },
    { ref: 'input_radio', component: RadioInput },
    { ref: 'input_button_radio', component: RadioButtonInput },
    { ref: 'input_phone', component: PhoneInput },
    { ref: 'input_date', component: DateInput },
    { ref: 'input_email', component: EmailInput },
    { ref: 'address_line', component: TextInput },
    { ref: 'address_city', component: TextInput },
    { ref: 'address_county', component: TextInput },
    { ref: 'address_postcode', component: TextInput },
    { ref: 'input_submit', component: SubmitButton },
    { ref: 'image', component: DecisionImage },
    { ref: 'term_slider', component: SliderInput },
    { ref: 'amount_slider', component: SliderInput },
    { ref: 'input_number', component: NumberInput },
    { ref: 'income', component: NumberInput },
    { ref: 'expense', component: NumberInput },
    { ref: 'input_hidden', component: HiddenInput },
    { ref: 'input_file', component: FileInput },
    { ref: 'output_table', component: TableInput },
    { ref: 'output_files', component: DocumentDisplay },
    { ref: 'input_checkbox', component: CheckboxInput },
    { ref: 'input_address', component: AddressInput },
    { ref: 'input_sso', component: SingleSignOnInput },
    { ref: 'output_widget', component: Widget },
];

export const errorComponent: ComponentListModel = {
    ref: 'text', content: 'Unable to find component', component: TextContent,
};
