import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import LooseObject from '@/models/Objects/LooseObject';

@Component
export default class HiddenInput extends Vue {
    @Prop() public props!: LooseObject;

    protected created() {
        EventBus.$on('requestHiddenValues', this.submitValue);
    }

    protected beforeDestroy() {
        EventBus.$off('requestHiddenValues', this.submitValue);
    }

    private submitValue() {
        EventBus.$emit('submitHiddenValue', this.props.value, this.props.applicationField, this.props.label);
    }
}
