import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import LooseObject from '@/models/Objects/LooseObject';
import { getFormattedString } from '@/utilities/mutate';
import router from '@/router';
import store from '@/store';

@Component
export default class TableInput extends Vue {
    @Prop() public props!: LooseObject;
    private searchText: string = '';

    private headStyleObj = {
        'border-bottom': '1px',
        'font-size': '1.1vw',
        'height': '40px',
        'min-height': '20px',
    };

    private bodyStyleObj = {
        'text-align': 'center',
        'box-shadow': '0px 0px 9px #00000029',
        'border-radius': '20px',
        'height': '10vw',
        'min-height': '90px',
        'font-size': '3vw',
        'font-weight': 'bold',
    };

    protected created() {
        /* tslint:disable:no-string-literal */
        if (this.props.actionColumns &&
            this.props.tableColumns[this.props.tableColumns.length - 1]['dataIndex'] !== 'action') {
            this.props.tableColumns[this.props.tableColumns.length] = {
                dataIndex: 'action',
                scopedSlots: {
                    customRender: 'action',
                },
                title: 'Action',
            };
        }
        this.props.tableColumns?.forEach((element: { [x: string]: any; }, index: any) => {
            if (element['searchable']) {
                this.props.tableColumns[index] = {
                    title: element['title'],
                    dataIndex: element['dataIndex'],
                    key: element['dataIndex'],
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'customRender',
                    },
                    onFilter: (value: string, record: { [x: string]: { toString: () => string; }; }) =>
                        record[element['dataIndex']]
                            .toString()
                            .toLowerCase()
                            .includes(value.toLowerCase()),
                };
            }
            if (element['sortable']) {
                this.props.tableColumns[index]['sorter'] = (a: any, b: any) => {
                    return a[element['dataIndex']].toString().localeCompare(b[element['dataIndex']].toString());
                };

                if (element['defaultOrder']) {
                    this.props.tableColumns[index]['defaultSortOrder'] = element['defaultOrder'].toString();
                }
            }
        });
        this.props.tableData?.forEach((element: { [x: string]: any; }, index: any) => {
            this.props.tableData[index]['key'] = index + 1;
        });
    }
    private handleSearch(selectedKeys: any[], confirm: () => void, dataIndex: any) {
        confirm();
        this.props.searchText = selectedKeys[0];
        this.props.searchedColumn = dataIndex;
    }

    private handleReset(clearFilters: () => void) {
        clearFilters();
        this.props.searchText = '';
    }

    private resolveAction(action: string, journey: string, record: LooseObject) {
        if (journey) {
            if (record.applicantidentifier) {
                store.commit('updateApplicantIdentifier', record.applicantidentifier.toUpperCase());
            } else if (record.applicantIdentifier) {
                store.commit('updateApplicantIdentifier', record.applicantIdentifier.toUpperCase());
            }
            if (record.applicationidentifier) {
                store.commit('updateApplicationIdentifier', record.applicationidentifier.toUpperCase());
            } else if (record.applicationIdentifier) {
                store.commit('updateApplicationIdentifier', record.applicationIdentifier.toUpperCase());
            } else {
                store.commit('updateApplicationIdentifier', undefined);
            }
            store.commit('updateWorkflow', journey);
            EventBus.$emit('resolveWorkflow');
        } else {
            const match = action.match(new RegExp(/(\{{[^{}]*\}})/g));
            match?.forEach((element) => {
                action = action.replace(element, record[element.substring(2, element.length - 2)]);
            });
            window.open(action, '_blank');
        }
    }

    private getWindowWidth() {
        let tableWidth = 0;
        if (this.props.tableColumns) {
            tableWidth = tableWidth + this.props.tableColumns.length;
        }
        if (this.props.actionColumns) {
            tableWidth = tableWidth + this.props.actionColumns.length;
        }
        if (window.innerWidth < tableWidth * 175) {
            return { x: tableWidth * 130 };
        } else {
            return {};
        }
    }

    private updateHeight() {
        this.$nextTick(() => {
            EventBus.$emit('update-form-panel-height');
            EventBus.$emit('update-side-panel-scroll');
        });
    }
}
