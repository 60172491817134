import { Component, Vue, Prop } from 'vue-property-decorator';
import LooseObject from '@/models/Objects/LooseObject';
import { getFormattedString } from '@/utilities/mutate';

@Component
export default class TextContent extends Vue {
    @Prop() public props!: LooseObject;

    private formattedContent: string = getFormattedString(this.props, this.props.content);
}
