import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import LooseObject from '@/models/Objects/LooseObject';

@Component
export default class SubmitButton extends Vue {
    @Prop() public props!: LooseObject;

    private submitForm() {
        this.props.editedMessageId !== undefined ?
            EventBus.$emit('submitForm', this.props.editedMessageId)
            :
            EventBus.$emit('submitForm');
    }

    private cancelForm() {
        EventBus.$emit('cancelForm', this.props.previousForm, this.props.previousFormName);
    }
}
