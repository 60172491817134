import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import LooseObject from '@/models/Objects/LooseObject';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import { getFormattedString } from '@/utilities/mutate';

@Component
export default class PhoneInput extends Vue {
    @Prop() public props!: LooseObject;
    @Prop() public value!: string;
    private validationArray: ValidationModel[] = [];
    private inputValue = '';

    protected created() {
        EventBus.$on('requestValue', this.submitValue);
        this.createValidationObject();
        this.checkForEditValue();
    }

    protected beforeDestroy() {
        EventBus.$off('requestValue', this.submitValue);
    }

    private isValueValid() {
        if (this.props.required && !this.value) {
          return false;
        }
        let valid = true;
        if (!this.props.minLength && this.props.maxLength) {
            const {min, max} = {min: this.props.minValue, max: this.props.maxValue};
            valid = this.value > min && this.value < max;
        }
        if (this.props.pattern && valid) {
            const match = this.value.match(new RegExp(this.props.pattern));
            valid = match ? true : false;
        }
        return valid;
    }

    private submitForm() {
        document.getElementById('submit')?.click();
    }

    private submitValue() {
        if (this.isValueValid()) {
            EventBus.$emit('submitValue', this.value, this.props.applicationField, this.props.label );
        }
    }

    private createValidationObject() {
        if (this.props.pattern !== undefined) {
            this.props.pattern = '[^0-9]';
        }
        this.validationArray = [
            {
                required: this.props.required,
                message: this.props.requiredMessage || 'Please fill out this field',
                trigger: 'blur',
            },
            {
                min: this.props.minLength,
                max: this.props.maxLength,
                message: this.props.validationMessage || `please keep between ${this.props.minLength} and ${this.props.maxLength} characters`,
                trigger: 'blur',
            },
            {
                pattern: new RegExp(this.props.pattern),
                message: this.props.validationMessage || `value does not meet the required input pattern`,
                trigger: 'blur',
            },
        ];
    }

    private updateInputValues(inputValue: string) {
        this.inputValue = getFormattedString(this.props, inputValue);
        this.$emit('update:value', this.inputValue);
    }

    private checkForEditValue() {
        if (this.props.defaultEditValue !== null) {
            this.updateInputValues(this.props.defaultEditValue.toString());
        }
    }
}
